@charset "utf-8";

// Size and positioning:
$max-width: 600px !default;

// Color:
$background-color: #fff !default;

// Text:
$font-family: 'proxima-nova', 'Helvetica', 'Avenir', 'Arial',
              'sans-serif' !default;
$font-size: 18px !default;
$font-weight: 400 !default;
$line-height: 1.5 !default;
$color: #4d4d4d !default;

// Header text:
$h-font-family: 'freight-sans-pro', 'proxima-nova',
                          'Helvetica' !default;
$h-color: #000 !default;
$h-a-hover-color: #504959 !default;

// Links:
$a-border-bottom-color: rgb(225, 225, 225) !default;

// Code blocks size and positioning:
$pre-max-width: 100%;
$pre-padding-top: 10px;
$pre-padding-bottom: 10px;
$pre-overflow: auto;
$pre-border-color: rgb(228, 228, 228);
$pre-border: 1px solid $pre-border-color;

// Code blocks text:
$pre-font-family: 'Menlo', 'Monaco', 'Consolas', 'Liberation Mono',
                  'Courier New', 'monospace' !default;
$pre-font-size: 13px;
$pre-font-weight: normal;
$pre-color: rgb(60, 55, 55); // xcode.syntax.plain


@import
  "svbtle/reset",
  "svbtle/media_query",
  "svbtle/base",
  "svbtle/syntax"
;
