body {
  // Color:
  background-color: $background-color;
  // Text:
  font-family: $font-family;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
  color: $color;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-font-feature-settings: 'kern' 1;
     -moz-font-feature-settings: 'kern' 1;
       -o-font-feature-settings: 'kern' 1;
          font-feature-settings: 'kern' 1;
  font-kerning: normal;
  padding-bottom: 100px;
}

header {
  // Size and positioning:
  margin-top: 30px;
  padding-bottom: 20px;
  // Text:
  font-family: $h-font-family;
  font-weight: 900;
  font-size: 18px;
}

header nav {
  margin-left: 50px;
}

header nav a {
  font-family: $font-family;
  font-weight: 400;
  font-size: 17px;
}

@include media-query($palm) {
  header {
    // Size and positioning:
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    padding-bottom: 0;
    // Border:
    border-bottom: 1px solid #eaeaea;
    // Text:
    text-align: center;
    line-height: 50px;
    font-size: 20px;
  }

  header nav {
    margin-left: 0;
  }

  main header {
    margin-bottom: 10px;
    border-bottom: 0;
  }
}

main {
  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 30px;
}

@include media-query($palm) {
  main {
    padding-left: 30px;
    padding-right: 30px;
  }
}

h1,
h2 {
  // Text:
  font-family: $h-font-family;
}

h1 {
  /* Size and positioning */
  margin-bottom: 0;

  /* Text */
  font-weight: bold;
  font-size: 43px;
  color: $h-color;
  line-height: 44px;
}

h2 {
  font-size: 25px;
  font-weight: bold;
  line-height: 28px;
}

@include media-query($palm) {
  h1 {
    font-size: 33px;
  }
  h1.header {
    line-height: 35px;
  }
}

p.post-meta {
  font-family: $h-font-family;
  font-size: 14px;
  color: $h-color;
  opacity: 0.3;
  padding-top: 0;
  padding-left: 20px;
  margin-top: 10px;
  margin-bottom: 25px;
}

ul, ol {
  /* Size and positioning */
  padding-left: 20px;
}

li {
  /* Size and positioning */
  padding-bottom: 10px;

  // Nested lists:
  ul, ol {
    padding-top: 10px;
  }
}

a {
  /* Text */
  color: $color;
  text-decoration: none;
}

h1 a {
  /* Text */
  color: $h-color;
}

h1 a:hover {
  /* Text */
  color: $h-a-hover-color;
}

p a,
li a {
  /* Text */
  border-bottom-color: $a-border-bottom-color;
  border-bottom-style: solid;
  border-bottom-width: 2px;
}

a.image {
  border-bottom-style: none;
}

code {
  font-family: $pre-font-family;
  font-size: 15px;
}

h2 {
  code {
    font-size: 22px;
  }
}

h3 {
  code {
    font-size: 17px;
  }
}

blockquote {
  border-left: 8px solid #504959;
  padding-left: 20px;
  margin-left: 0;
}

hr {
  background-color: #f4f3f3;
  margin: 0 auto;
  width: 50%;
  border-radius: 5px;
  max-width: 430px;
  color: #f4f3f3;
  height: 5px;
  border: 0px;
  margin-top: 50px;
  margin-bottom: 50px;
}

article img {
  /* Size and positioning */
  width: 100%;
}

table {
  width: 100%;
  border-spacing: 0;
  border-top: 1px solid $pre-border-color;
  border-left: 1px solid $pre-border-color;
  border-right: 1px solid $pre-border-color;
  font-size: 15px;
  text-align: center;
}

th, td {
  padding-top: 5px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 5px;
  border-right: 1px solid $pre-border-color;
  border-bottom: 1px solid $pre-border-color;
}

th:last-child, td:last-child {
  border-right: 0;
}

.twitter-share-button {
  margin-top: 20px;
}

/* Index */
main header {
  padding-bottom: 20px;
}

main header figure {
  width: 110px;
  height: 110px;
  border: 5px solid #000;
  border-radius: 100%;
  text-align: center;
  margin: 0 auto;
}
main header figure a {
  background-image: url('/images/me.jpg');
  text-indent: -9999px;
  margin: 0 auto;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-size: 100%;
  outline: 0;
  display: block;
  margin-top: 5px;
}

main header h1 {
  font-size: 14px;
  text-transform: uppercase;
  margin: 0 auto;
  margin-top: 10px;
  padding-bottom: 0;
  text-align: center;
}

main header h2 {
  font-size: 20px;
  font-weight: 300;
  line-height: 26px;
  margin-top: 10px;
  font-family: $font-family;
  padding-left: 25px;
  padding-right: 25px;
}

main header {
  ul {
    width: 180px;
    padding: 0;
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    li {
      padding-left: 8%;
      padding-right: 8%;
      margin: 0 auto;
      width: 15%;
      display: inline-block;
      text-align: center;
      vertical-align: center;
      a {
        border-bottom: 0;
      }
    }
  }
  .icon svg {
    display: inline-block;
    vertical-align: middle;
    path {
      fill: #828282;
    }
  }
}

nav h1 {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 900;
  color: #504959;
}

nav ul {
  list-style-type: none;
}

.patrons-only {
  background:
    linear-gradient(
      to left top,
      rgba(60, 55, 70, 1.0),
      rgba(60, 55, 70, 0.8),
    );
  color: #fff;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 40px;
  letter-spacing: 0.1em;
  font-family: America, sans-serif;
  font-weight: 700;
  font-size: 14px;

  p.amount {
    text-transform: uppercase;
    padding-top: 20px;
  }

  p.details {
    letter-spacing: 0;
    text-transform: unset;
    font-size: 12px;
    padding-left: 50px;
    padding-right: 50px;

    code {
      font-size: 12px;
    }
  }

  a {
    display: inline-block;
    border: 1px solid #fff;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-family: America, sans-serif;
    font-weight: 700;
    font-size: 12px;
    padding: 10px;
    margin-top: 10px;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
    min-width: 200px;
  }
}

footer {
  font-size: 13px;
  text-align: center;

  hr {
    margin-top: 0;
  }
}

.source-file {
  border-top: $pre-border;
  margin: 0;
  padding: 0;
}

.source-file h1 {
  font-family: $pre-font-family;
  font-weight: normal;
  color: #7f7f7f;
  font-size: 10px;
  padding: 0;
  top: -17px;
  background-color: white;
  line-height: 17px;
  padding-bottom: 0;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: -50px;
  margin-left: 10px;
  margin-bottom: 10px;
  position: relative;
  display: inline-block;
}

.source-file h1 a {
  color: #7f7f7f;
}

pre.file {
  border-top: none;
  padding-top: 0;
  margin-top: -20px;
}

pre .hi {
  background-color: rgba(150, 150, 255, 0.1);
  display: inline-block;
  .ln {
    color: rgb(180, 176, 176);
  }
}

pre .add {
  background-color: rgba(150, 255, 150, 0.3);
  display: inline-block;
  .ln {
    color: rgb(180, 176, 176);
  }
}

pre .rm {
  background-color: rgba(255, 150, 150, 0.3);
  display: inline-block;
  .ln {
    color: rgb(180, 176, 176);
  }
}

svg#swift-driver-treemap {
  width: 600px;
  height: 800px;
  margin: 0 auto;
  text-align: center;
  display: block;

  text {
    font-size: 10px;
    font-family: $pre-font-family;
  }

  tspan:last-child {
    font-size: 8px;
    fill-opacity: 0.8;
  }

  .node--hover rect {
    stroke: #000;
  }
}

@include media-query($palm) {
  svg#swift-driver-treemap {
    width: 350px;
    height: 900px;
  }
}

nav.swiftc-toc {
  max-width: 150px;
  margin-top: 40px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 9pt;
  float: left;
  line-height: 16px;
  border-right: 1px solid rgb(202, 202, 202);
  color: rgb(117, 117, 117);

  ol {
    list-style-type: upper-roman;
    list-style-position: inside;
    padding-left: 0;
  }

  p {
    text-align: center;
    padding-bottom: 15px;
  }

  a {
    border-bottom-style: none;
  }

  a:hover {
    border-bottom-color: $a-border-bottom-color;
    border-bottom-style: solid;
    border-bottom-width: 2px;
  }

  h1 {
    font-family: $font-family;
    line-height: $line-height;
  }

  li {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  li.current {
    font-weight: 800;

    a:hover {
      border-bottom-color: rgb(175, 175, 175);
      border-bottom-style: solid;
      border-bottom-width: 2px;
    }
  }
}

@include media-query($sidebar) {
  nav.swiftc-toc {
    display: none;
  }
}

.twitter-tweet {
  margin: 0 auto;
}
