pre {
  max-width: $pre-max-width;
  padding-top: $pre-padding-top;
  padding-bottom: $pre-padding-bottom;
  overflow: $pre-overflow;
  border-top: $pre-border;
  border-bottom: $pre-border;
  font-size: $pre-font-size;
  font-family: $pre-font-family;
  font-weight: $pre-font-weight;
  color: $pre-color;
}

$syntax-attribute-color: rgb(131, 108, 40);
$syntax-character-color: rgb(93, 131, 46);
$syntax-cli-color: rgb(15, 158, 122);
$syntax-cli-executable-color: rgb(18, 112, 87);
$syntax-cli-option-color: rgb(2, 114, 142);
$syntax-cli-output-color: rgb(136, 136, 136);
$syntax-cli-prompt-color: rgb(153, 153, 153);
$syntax-cli-variable-color: rgb(138, 31, 170);
$syntax-comment-color: rgb(135, 135, 135);
$syntax-comment-doc-color: rgb(0, 153, 106);
$syntax-comment-doc-keyword-color: rgb(117, 141, 173);
$syntax-debugger-cursor-color: rgb(2, 173, 2);
$syntax-debugger-line-number-color: rgb(128, 128, 128);
$syntax-diagnostic-warning-color: rgb(197, 5, 197);
$syntax-diagnostic-error-color: rgb(197, 5, 5);
$syntax-diagnostic-note-color: rgb(0, 107, 156);
$syntax-diagnostic-remark-color: rgb(29, 0, 156);
$syntax-diagnostic-cursor-color: rgb(2, 173, 2);
$syntax-fs-directory-color: rgb(15, 158, 122);
$syntax-fs-file-color: rgb(2, 114, 142);
$syntax-grep-match-color: rgb(240, 49, 138);
$syntax-identifier-class-color: rgb(154, 82, 172);
$syntax-identifier-class-system-color: rgb(163, 74, 36);
$syntax-identifier-constant-color: rgb(18, 137, 126);
$syntax-identifier-constant-system-color: rgb(0, 153, 145);
$syntax-identifier-function-color: rgb(42, 134, 192);
$syntax-identifier-function-system-color: rgb(51, 153, 204);
$syntax-identifier-macro-color: rgb(155, 109, 212);
$syntax-identifier-macro-system-color: rgb(223, 107, 203);
$syntax-identifier-type-color: rgb(99, 72, 143);
$syntax-identifier-type-system-color: rgb(178, 33, 132);
$syntax-identifier-variable-color: rgb(82, 121, 180);
$syntax-identifier-variable-system-color: rgb(82, 121, 180);
$syntax-keyword-color: rgb(110, 72, 170);
$syntax-line-number-color: rgb(228, 228, 228);
$syntax-number-color: rgb(111, 147, 60);
$syntax-preprocessor-color: rgb(207, 110, 148);
$syntax-string-color: rgb(57, 115, 245);
$syntax-string-interpolation: rgb(0, 128, 128);
$syntax-url-color: rgb(32, 63, 187);
$syntax-ast-parenthesis-color: $syntax-attribute-color;
$syntax-ast-stmt-color: $syntax-identifier-class-system-color;
$syntax-ast-decl-color: $syntax-preprocessor-color;
$syntax-ast-expr-color: $syntax-identifier-function-color;
$syntax-ast-other-color: $syntax-comment-color;

// Jekyll
pre, code {
  .c { color: $syntax-comment-color; font-style: italic } // Comment, xcode.syntax.comment
  .err { color: #a61717; background-color: #e3d2d2 } // Error
  .k { color: $syntax-keyword-color } // Keyword, xcode.syntax.keyword
  // .o { } // Operator
  .cm { color: $syntax-comment-doc-color; font-style: italic } // Comment.Multiline
  .cp { color: $syntax-comment-doc-keyword-color; font-style: italic } // Comment.Preproc
  .c1 { color: $syntax-comment-color; font-style: italic } // Comment.Single
  .cs { color: $syntax-comment-doc-keyword-color; font-style: italic } // Comment.Special
  .gd { color: #000; background-color: #fdd } // Generic.Deleted
  .gd .x { color: #000; background-color: #faa } // Generic.Deleted.Specific
  .ge { font-style: italic } // Generic.Emph
  .gr { color: #a00 } // Generic.Error
  .gh { color: #999 } // Generic.Heading
  .gi { color: #000; background-color: #dfd } // Generic.Inserted
  .gi .x { color: #000; background-color: #afa } // Generic.Inserted.Specific
  .go { color: $syntax-cli-output-color; font-style: italic } // Generic.Output
  .gp { color: $syntax-cli-prompt-color } // Generic.Prompt
  .gs { font-weight: bold } // Generic.Strong
  .gu { color: #aaa } // Generic.Subheading
  .gt { color: #a00 } // Generic.Traceback
  .kc { font-weight: bold } // Keyword.Constant
  .kd { font-weight: bold } // Keyword.Declaration
  .kp { font-weight: bold } // Keyword.Pseudo
  .kr { font-weight: bold } // Keyword.Reserved
  .kt { color: $syntax-identifier-type-system-color } // Keyword.Type
  .m { color: $syntax-number-color } // Literal.Number
  .s { color: $syntax-string-color } // Literal.String
  .na { color: $syntax-attribute-color } // Name.Attribute
  .nb { color: #0086B3 } // Name.Builtin
  .nc { color: $syntax-identifier-class-color; } // Name.Class
  .no { color: $syntax-identifier-constant-color } // Name.Constant
  .ni { color: #800080 } // Name.Entity
  .ne { color: #900 } // Name.Exception
  .nf { color: $syntax-identifier-function-color } // Name.Function
  .nn { color: #555 } // Name.Namespace
  .nt { color: #000080 } // Name.Tag
  // .nv { } // Name.Variable
  .ow { font-weight: bold } // Operator.Word
  .w { color: #bbb } // Text.Whitespace
  .mf { color: #099 } // Literal.Number.Float
  .mh { color: #099 } // Literal.Number.Hex
  .mi { color: #099 } // Literal.Number.Integer
  .mo { color: #099 } // Literal.Number.Oct
  .sb { color: #d14 } // Literal.String.Backtick
  .sc { color: #d14 } // Literal.String.Char
  .sd { color: #d14 } // Literal.String.Doc
  .s2 { color: #d14 } // Literal.String.Double
  .se { color: #d14 } // Literal.String.Escape
  .sh { color: #d14 } // Literal.String.Heredoc
  .si { color: #d14 } // Literal.String.Interpol
  .sx { color: #d14 } // Literal.String.Other
  .sr { color: #009926 } // Literal.String.Regex
  .s1 { color: #d14 } // Literal.String.Single
  .ss { color: #990073 } // Literal.String.Symbol
  .bp { color: #999 } // Name.Builtin.Pseudo
  .vc { color: #008080 } // Name.Variable.Class
  .vg { color: #008080 } // Name.Variable.Global
  .vi { color: #008080 } // Name.Variable.Instance
  .il { color: #099 } // Literal.Number.Integer.Long

  .doc { color: $syntax-comment-doc-color; font-style: italic; } // Documentation markup
  .dock { color: $syntax-comment-doc-keyword-color; font-style: italic; } // Documentation markup keywords
  .pps { color: $syntax-preprocessor-color } // Preprocessor statements
  .ot { color: $syntax-identifier-type-system-color } // Other type names
  .ofm { color: $syntax-identifier-function-system-color } // Other function and method names
  .oig { color: $syntax-identifier-constant-system-color } // Other instance variables and globals

  .diag { font-weight: bold } // Compiler diagnostic message
  .dw { color: $syntax-diagnostic-warning-color } // Compiler diagnostic "warning" text
  .de { color: $syntax-diagnostic-error-color } // Compiler diagnostic "error" text
  .dn { color: $syntax-diagnostic-note-color } // Compiler diagnostic "note" text
  .dr { color: $syntax-diagnostic-remark-color } // Compiler diagnostic "remark" text
  .dc { color: $syntax-diagnostic-cursor-color } // Compiler diagnostic cursor

  .cmk { color: $syntax-keyword-color } // CMake keyword
  .cma { color: $syntax-attribute-color } // CMake argument
  // .cmv { color: #8e3802 } // CMake variable

  // Xcode
  .a { color: $syntax-attribute-color }
  .ch { color: $syntax-character-color }
  .c { color: $syntax-comment-color }
  .d { color: $syntax-comment-doc-color; font-style: italic }
  .dk { color: $syntax-comment-doc-keyword-color; font-style: italic }
  .cl { color: $syntax-identifier-class-color }
  .cls { color: $syntax-identifier-class-system-color }
  .cn { color: $syntax-identifier-constant-color }
  .cns { color: $syntax-identifier-constant-system-color }
  .f { color: $syntax-identifier-function-color }
  .fs { color: $syntax-identifier-function-system-color }
  .m { color: $syntax-identifier-macro-color }
  .ms { color: $syntax-identifier-macro-system-color }
  .t { color: $syntax-identifier-type-color }
  .ts { color: $syntax-identifier-type-system-color }
  .v { color: $syntax-identifier-variable-color }
  .vs { color: $syntax-identifier-variable-system-color }
  .k { color: $syntax-keyword-color }
  .num { color: $syntax-number-color }
  .pp { color: $syntax-preprocessor-color }
  .s { color: $syntax-string-color }
  .u { color: $syntax-url-color }

  // Custom

  // Command-line
  .cmd { color: $syntax-cli-color } // Command-line globally installed program, or a command on a prompt
  .cexec { color: $syntax-cli-executable-color } // Command-line executable
  .copt { color: $syntax-cli-option-color } // Command-line executable option
  .cvar { color: $syntax-cli-variable-color } // Command-line variable value
  .cpr { color: $syntax-cli-prompt-color } // Command-line prompt
  .cout { color: $syntax-cli-output-color; font-style: italic } // Command-line output

  // AST
  .astp { color:$syntax-ast-parenthesis-color } // Parenthesis
  .aststmt { color: $syntax-ast-stmt-color } // Statment
  .astdecl { color: $syntax-ast-decl-color } // Decl
  .astexpr { color: $syntax-ast-expr-color } // Expr
  .asto { color: $syntax-ast-other-color } // Minutae

  // Debugger
  .dc { color: $syntax-debugger-cursor-color }
  .dln { color: $syntax-debugger-line-number-color }

  // Directory structure
  .fsd { color: $syntax-fs-directory-color } // Directory name
  .fsf { color: $syntax-fs-file-color } //  File name

  // Grep
  .gm { color: $syntax-grep-match-color; font-weight: bold } // grep match

  // Other
  .ln {
    color: $syntax-line-number-color;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  } // Line number
  .si { color: $syntax-string-interpolation } // String interpolation

}