// Use media queries like this:
// @include media-query($palm) {
//   .wrapper {
//     padding-right: ...;
//     padding-left: ...;
//   }
// }
$palm: 600px !default;
$laptop: 800px !default;
$sidebar: 1050px !default;
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

